import { animate, animateChild, group, keyframes, query, state, style, transition, trigger } from "@angular/animations";

const enterTransition = transition(':enter', [
  style({ opacity: 0 }),
  animate('250ms', style( { opacity: 1})),
]);

const leaveTransition = transition(':leave', [
  style({ opacity: 1 }),
  animate('200ms', style( { opacity: 0})),
]);

export const fadeInOut = trigger('fadeInOut', [enterTransition, leaveTransition]);

const rotateTransition = transition(':enter', [
  animate('1000ms', keyframes([
    style({ transform: 'rotate(0deg)', offset: '0'}),
    style({ transform: 'rotate(2turn)', offset: '1'})
  ]))
]);
export const rotateButton = trigger('rotate', [rotateTransition]);


const submenuAnimation = [
  state('hidden', style({ height: '0', overflow: 'hidden' })),
  state('visible', style({ height: '*' })),
  transition('visible <=> hidden', [
    style({ overflow: 'hidden' }),
    animate('{{transitionParams}}')
  ]),
  transition('void => *', animate(0)
  )];
export const submenuTrigger = trigger('submenu', submenuAnimation);


/**
*@description
* Animação para a transição entre páginas
*/
export const slideInAnimation =
  trigger('routeAnimations', [
    transition('HomePage <=> AboutPage', [
      style({position: 'relative'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ]),
      query(':enter', [style({left: '-100%'})], {optional: true}),
      query(':leave', animateChild(), {optional: true}),
      group([
        query(':leave', [animate('300ms ease-out', style({left: '100%'}))], {optional: true}),
        query(':enter', [animate('300ms ease-out', style({left: '0%'}))], {optional: true}),
      ]),
    ]),
    transition('* <=> *', [
      style({position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        {optional: true},
      ),
      query(':enter', [style({left: '-100%'})], {optional: true}),
      query(':leave', animateChild(), {optional: true}),
      group([
        query(':leave', [animate('200ms ease-out', style({left: '100%', opacity: 0}))], {
          optional: true,
        }),
        query(':enter', [animate('300ms ease-out', style({left: '0%'}))], {optional: true}),
        query('@*', animateChild(), {optional: true}),
      ]),
    ]),
  ]);
